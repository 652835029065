import React, {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {Button, ButtonGroup, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from "moment";
import {Race} from "./Races";
import {fetchDataAsync} from "../../helpers/requests";

const validateRace = ({name, startDate, endDate, displayRows, reward, bonus, optIn, multiplier}: Race) => {
    let errors: any = {}
    if (!name) {
        errors.name = 'Name is required'
    } else if (name.length > 50) {
        errors.name = 'Name must be at most 50 characters long'
    }
    if (!startDate) {
        errors.startDate = 'Start Date is required'
    }
    if (!endDate) {
        errors.endDate = 'End Date is required'
    }
    if (displayRows === undefined) {
        errors.displayRows = 'Display Rows is required'
    }
    if (reward === undefined) {
        errors.reward = 'Reward is required'
    }
    return errors
}


const RaceForm = (props: any) => {
    const [values, setValues]: [Race, Dispatch<SetStateAction<Race>>] = useState(props.initialState);
    const [errors, setErrors]: [any, Dispatch<SetStateAction<any>>] = useState({});
    const [raceSchemes, setRaceSchemes] = useState<Race[]>([]);
    useEffect(() => {
        fetchDataAsync(`${process.env.REACT_APP_API_URL}/race-schemas/`, setRaceSchemes)
    }, []);
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        const errors = validateRace(values)
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            props.postData(values)
        }
    }
    const InputError = ({name}: any) => {
        if (errors[name]) {
            return <FormText color={'danger'}>{errors[name]}</FormText>
        }
        return null
    }
    const FormStyle = {
        margin: 20
    }
    const {name, startDate, endDate, displayRows, reward, bonus, optIn, multiplier, race_schema_id} = values

    return <Form style={FormStyle} onSubmit={handleSubmit}>
        <FormGroup row>
            <Label for="name">Name</Label>
            <Input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                value={name}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setValues({
                    ...values,
                    name: e.currentTarget.value
                })}
            />
            <InputError name={'name'}/>
        </FormGroup>
        <FormGroup row>
            <Label row for="startDate">Start Date</Label>
            <DatePicker
                value={startDate}
                dateFormat="DD-MM-YYYY"
                timeFormat="HH:mm"
                onChange={val => setValues({...values, startDate: moment(val)})}
            />
            <InputError name={'startDate'}/>
        </FormGroup>
        <FormGroup row>
            <Label for="endDate">End Date</Label>
            <DatePicker
                value={endDate}
                dateFormat="DD-MM-YYYY"
                timeFormat="HH:mm"
                onChange={val => setValues({...values, endDate: moment(val)})}
            />
            <InputError name={'endDate'}/>
        </FormGroup>
        <FormGroup row>
            <Label for="displayRows">Display Rows</Label>
            <Input
                type="number"
                name="displayRows"
                id="displayRows"
                placeholder="Display Rows"
                min={0}
                value={displayRows}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setValues({
                    ...values,
                    displayRows: Number(e.currentTarget.value)
                })}
            />
            <InputError name={'displayRows'}/>
        </FormGroup>
        <FormGroup row>
            <Label for="reward">Reward</Label>
            <Input
                type="number"
                name="reward"
                id="reward"
                placeholder="Reward"
                min={1}
                value={reward}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setValues({
                    ...values,
                    reward: Number(e.currentTarget.value)
                })}
            />
            <InputError name={'reward'}/>
        </FormGroup>
        <FormGroup row>
            <Label check for={'bonus'}>Bonus</Label>
            <ButtonGroup>
                <Button
                    color="primary"
                    onClick={() => setValues({...values, bonus: false})}
                    active={!bonus}>
                    No
                </Button>
                <Button
                    color="primary"
                    onClick={() => setValues({...values, bonus: true})}
                    active={bonus}>
                    Yes
                </Button>
            </ButtonGroup>
            <InputError name={'bonus'}/>
        </FormGroup>
        <FormGroup row>
            <Label check for={'optIn'}>Opt In</Label>
            <ButtonGroup>
                <Button
                    color="primary"
                    onClick={() => setValues({...values, optIn: false})}
                    active={!optIn}>
                    No
                </Button>
                <Button
                    color="primary"
                    onClick={() => setValues({...values, optIn: true})}
                    active={optIn}>
                    Yes
                </Button>
            </ButtonGroup>
            <InputError name={'optIn'}/>
        </FormGroup>
        <FormGroup row>
            <Label check for={'multiplier'}>Multiplier Scheme</Label>
            <ButtonGroup>
                <Button
                    color="primary"
                    onClick={() => setValues({...values, multiplier: false})}
                    active={!multiplier}>
                    No
                </Button>
                <Button
                    color="primary"
                    onClick={() => setValues({...values, multiplier: true})}
                    active={multiplier}>
                    Yes
                </Button>
            </ButtonGroup>
            <InputError name={'multiplier'}/>
        </FormGroup>
        {
            multiplier &&
            <FormGroup row>
                <Input
                    type="select"
                    name="race_schema_id"
                    id="race_schema_id"
                    placeholder="Multiplier Scheme"
                    value={race_schema_id}
                    required
                    onChange={(e) => setValues({
                        ...values,
                        race_schema_id: parseInt(e.currentTarget.value, 10)
                    })}
                >
                    <option disabled hidden selected>Select Multiplier Scheme</option>
                    {
                        raceSchemes.map((raceSchema: Race, index) => (
                            <option
                                key={index} value={raceSchema.ID}>{raceSchema.name}</option>
                        ))
                    }
                </Input>
                <InputError name={'race_schema_id'}/>
            </FormGroup>
        }
        <Button color="primary" type={'submit'}>Save</Button>
    </Form>
}

export default RaceForm;