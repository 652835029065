import React from "react";
import {Container} from "reactstrap";
import 'react-datetime/css/react-datetime.css';
import moment from "moment";
import {Race} from "./Races";
import {RaceSchema} from "../RaceSchemas/RaceSchemas";
import axios from "axios";
import RaceForm from "./RaceForm";
import { getToken } from '../utils/authStorage'

const CreateRace = (props: any) => {
    const initialState: Race = {
        name: '',
        startDate: moment(),
        endDate: moment(),
        displayRows: 0,
        reward: 1,
        bonus: false,
        optIn: false,
        multiplier: false,
        race_schema_id: undefined
    }

    const postData = async (values: RaceSchema | Race) => {
        try {
            const token = getToken()
            await axios({
                url: `${process.env.REACT_APP_API_URL}/races/`,
                method: 'post',
                data: values,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                withCredentials: true
            })
            props.history.push("/races")
        } catch (e) {
            alert(e.message)
        }
    }

    return (
        <Container fluid>
            <h2 className="pageTitle">New Race</h2>
            <RaceForm initialState={initialState} postData={postData}/>
        </Container>
    )
}

export default CreateRace;