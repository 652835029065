import React from 'react'
// import {useState} from "react";
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap'
// import {Collapse, Container, NavbarToggler} from "reactstrap";

const Sidebar = (props: any) => {
	// const [collapsed, setCollapsed] = useState(false);
	// const toggleNavbar = () => setCollapsed(!collapsed);
	const {userType} = props
	if (userType === 'princess') {
		return (
			<Navbar dark className="menubar align-content-start d-flex" style={{
				position: 'sticky',
				top: 0,
				left: 0,
				minWidth: '15%',
				width: '15%'
			}}>
				{/*<NavbarToggler onClick={toggleNavbar} />*/}
				<NavbarBrand href="/" className="mr-auto p-2 logo">Princess Ops</NavbarBrand>

				{/*<Collapse isOpen={!collapsed}>*/}
				<Nav className="d-flex flex-column align-content-start">
					<NavItem>
						<NavLink href="/" className="link-sidebar">Home</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="/lpusers" className="link-sidebar">LP Data</NavLink>
					</NavItem>
					{/*<NavItem>*/}
					{/*    <NavLink href="/races" className="link-sidebar">Races</NavLink>*/}
					{/*</NavItem>*/}
					{/*<NavItem>*/}
					{/*    <NavLink href="/race-schemas" className="link-sidebar">Race Schemas</NavLink>*/}
					{/*</NavItem>*/}
					<NavItem>
						<NavLink href="/signups" className="link-sidebar">Princess Signup Tracking</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="/deposits" className="link-sidebar">Princess Deposit Tracking</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="/signup-conversion" className="link-sidebar">Signup Conversions</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="/deposit-conversion" className="link-sidebar">Deposit Conversions</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="/ninja-signup" className="link-sidebar">MegApuesta Signup Tracking</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="/ninja-deposit" className="link-sidebar">MegApuesta Deposit Tracking</NavLink>
					</NavItem>
				</Nav>
				{/*</Collapse>*/}

			</Navbar>

		)
	}
	return (
		<Navbar dark className="menubar align-content-start d-flex" style={{
			position: 'sticky',
			top: 0,
			left: 0,
			minWidth: '15%',
			width: '15%'
		}}>
			<NavbarBrand href="/" className="mr-auto p-2 logo">CashPot Ops</NavbarBrand>
			<Nav className="d-flex flex-column align-content-start">
				<NavItem>
					<NavLink href="/" className="link-sidebar">Home</NavLink>
				</NavItem>
				<NavItem>
					<NavLink href="/signups" className="link-sidebar">CashPot Signup Tracking</NavLink>
				</NavItem>
				<NavItem>
					<NavLink href="/deposits" className="link-sidebar">CashPot Deposit Tracking</NavLink>
				</NavItem>
			</Nav>

		</Navbar>

	)

}

export default Sidebar
