import React from "react";
import moment from "moment";
import {LPUser} from "./lpusers";
import {Table} from "reactstrap";

export const UserTable = (props: any) => {
    return (
        <Table striped size="sm">
            <thead>
            <tr style={{whiteSpace: 'nowrap'}}>
                <th>Landing Page</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Affiliate Token</th>
                <th>Status</th>
                <th>Registered at</th>
                <th>Notified at</th>
            </tr>
            </thead>
            <tbody>
            {props.loading && <div style={{
                position: 'absolute',
                width: 'calc(100vw - 15%)',
                height: '100vh',
                top: 0,
                left: '15%',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <img style={{alignSelf: 'center'}} src={require('../../assets/loader.svg')} alt={'loader'}/>
            </div>}
            {!props.loading && !!props.data && props.data.map((user: LPUser, index: number) => (
                <tr key={index} style={{cursor: user.notifications ? 'pointer' : 'initial'}} onClick={() => {
                    if (user.notifications) {
                        props.setModalOpen(true)
                        props.setUser(user)
                    }
                }}>
                    <td>{user.landingPage}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.tid}</td>
                    <td>{user.status}</td>
                    <td>{moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{moment(user.notifiedAt).format('DD/MM/YYYY HH:mm:ss').includes('01/01/0001') ? '-' : moment(user.notifiedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}