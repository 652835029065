import React from "react";
import {Table} from "reactstrap";
import {DepositConversion} from "./depositConversion";
import moment from "moment";

export const DepositConversionTable = (props: any) => {
    return (
        <Table striped size="sm">
            {props.loading && <div style={{
                position: 'absolute',
                width: 'calc(100vw - 15%)',
                height: '100vh',
                top: 0,
                left: '15%',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <img style={{alignSelf: 'center'}} src={require('../../assets/loader.svg')} alt={'loader'}/>
            </div>}
            <thead>
            <tr>
                <th>User ID</th>
                <th>First<br/> name</th>
                <th>Last<br/> name</th>
                <th>Payment<br/> Id</th>
                <th>Conversion<br/> name</th>
                <th>Amount</th>
                <th>Payment<br/> Time</th>
                <th>Affiliate<br/> Id</th>
                <th>Affiliate<br/> Token</th>
                <th>Response<br/> Status</th>
                <th>Error <br/> Type</th>
                <th>Conversion<br/> sent at</th>
            </tr>
            </thead>
            <tbody>
            {props.deposits && props.deposits.map((deposit: DepositConversion) => (
                <tr>
                    <td>{deposit.userId}</td>
                    <td>{deposit.firstName}</td>
                    <td>{deposit.lastName}</td>
                    <td>{deposit.paymentId}</td>
                    <td>{deposit.conversionName}</td>
                    <td>{deposit.amount}</td>
                    <td>{moment(deposit.paymentTime).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{deposit.affiliateId}</td>
                    <td>{deposit.myAffiliateToken}</td>
                    <td>{deposit.responseStatus}</td>
                    <td>{deposit.errorType}</td>
                    <td>{moment(deposit.conversionSentAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}
