import React from "react";
import { Table} from "reactstrap";
import {Deposit} from "./deposits";
import moment from "moment";

export const DepositsTable = (props: any) => {
    return (
        <Table striped size="sm">
            {props.loading && <div style={{
                position: 'absolute',
                width: 'calc(100vw - 15%)',
                height: '100vh',
                top: 0,
                left: '15%',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <img style={{alignSelf: 'center'}} src={require('../../assets/loader.svg')} alt={'loader'}/>
            </div>}
            <thead>
            <tr>
                <th>User ID</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Payment Id</th>
                <th>Amount</th>
                <th>Payment type</th>
                <th>Affiliate Id</th>
                <th>Affiliate Token</th>
                <th>Platform</th>
                <th>Response</th>
                <th>Reported at</th>
            </tr>
            </thead>
            <tbody>
            {props.deposits && props.deposits.map((deposit: Deposit) => (
                <tr>
                    <td>{deposit.UserId}</td>
                    <td>{deposit.FirstName}</td>
                    <td>{deposit.LastName}</td>
                    <td>{deposit.PaymentId}</td>
                    <td>{deposit.Amount}</td>
                    <td>{deposit.PaymentType}</td>
                    <td>{deposit.AffiliateId}</td>
                    <td>{deposit.AffiliateToken}</td>
                    <td>{deposit.platform === 'princess'? 'ninja':deposit.platform}</td>
                    <td>{deposit.TrackStatus}</td>
                    <td>{moment(deposit.CreatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}
