import React from "react";
import {Table} from "reactstrap";
import {NinjaSignup} from "./ninjaSignup";
import moment from "moment";

export const NinjaSignupTable = (props: any) => {
    return (
        <Table striped size="sm">
            {props.loading && <div style={{
                position: 'absolute',
                width: 'calc(100vw - 15%)',
                height: '100vh',
                top: 0,
                left: '15%',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <img style={{alignSelf: 'center'}} src={require('../../assets/loader.svg')} alt={'loader'}/>
            </div>}
            <thead>
            <tr>
                <th>Username</th>
                <th>Registration Date</th>
                <th>Affiliate Token</th>
                <th>Response Status</th>
                <th>Response Payload</th>
                <th>Tracked At</th>

            </tr>
            </thead>
            <tbody>
            {props.deposits && props.deposits.map((deposit: NinjaSignup) => (
                <tr>
                    <td>{deposit.username}</td>
                    <td>{moment(deposit.registrationDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{deposit.affiliateToken}</td>
                    <td>{deposit.responseStatus}</td>
                    <td>{deposit.responsePayload}</td>
                    <td>{moment(deposit.trackedAt).format('DD/MM/YYYY HH:mm:ss')}</td>

                </tr>
            ))}
            </tbody>
        </Table>
    )
}
