import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import { Paginator } from '../marketing/paginator'
import { SignupsTable } from './signupsTable'
import { Search } from '../utils/search'
import { downloadExportedFile } from '../../helpers/requests'
import { getToken, getUserData } from '../utils/authStorage'

export interface User {
	AffiliateId: number
	AffiliateToken: string
	CreatedAt: Date
	FirstName: string
	LastName: string
	RegistrationTime: string
	TrackStatus: string
	userId: number
	platform: string
}


const Signups = (props: any) => {
	const userType = getUserData('userType')
	let baseUrl = `${process.env.REACT_APP_SIGNUPS_API}`
	if (userType === 'cashpot') {
		baseUrl = `${baseUrl}/cashpot`
	}

	const [info, setInfo] = useState<User[]>([])
	const [page, setPage] = useState<Number>(1)
	const [pageChanged, hasPageChanged] = useState<boolean>(false)
	const [numberOfUsers, setNumberOfUsers] = useState<Number>(0)
	const [pagination, setPagination] = useState<Number>(100)
	const [search, setSearch] = useState<string>('')
	const [platform, setPlatform] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [maxPage, setMaxPage] = useState<Number>(0)
	const [token, setToken] = useState('')

	const [endDate, setEndDate] = useState<string>('')
	const [startDate, setStartDate] = useState<string>('')
	const [formReset, isFormReset] = useState<boolean>(false)
	const [exportable, setExportable] = useState<boolean>(false)

	const fetchData = () => {
		setLoading(true)
		isFormReset(false)
		let canExport = false
		let url = `${baseUrl}/signups/list?page=${page}`

		if (search !== '') {
			url = `${url}&search=${search}`
			canExport = true
		}
		if (platform !== '') {
			url = `${url}&platform=${platform}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
			canExport = true
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
			canExport = true
		}
		setExportable(canExport)

		return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
			.then(response => response.json())
			.then(response => {
				setInfo(response)
			})
			.catch(error => setInfo([]))
			.finally(() => {
				setLoading(false)
			})
	}

	const exportData = () => {
		setLoading(true)
		let url = `${baseUrl}/signups/export?1=1`

		if (search !== '') {
			url = `${url}&search=${search}`
		}
		if (platform !== '') {
			url = `${url}&platform=${platform}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
		}

		return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
			.then(downloadExportedFile)
			.catch(error => console.log(error))
			.finally(() => {
				setLoading(false)
			})
	}

	const fetchNumberOfEntries = () => {
		setLoading(true)
		let url = `${baseUrl}/signups/countData?1=1`
		if (search !== '') {
			url = `${url}&search=${search}`
		}
		if (platform !== '') {
			url = `${url}&platform=${platform}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
		}
		return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
			.then(response => response.json())
			.then(response => {
				setNumberOfUsers(response)
			})
			.catch(error => setInfo([]))
			.finally(() => {
				setLoading(false)
			})
	}
	useEffect(() => {
		const tokenFetch = async () => {
			const tok = getToken()
			if (tok) {
				setToken(tok)
			}
		}
		tokenFetch()
	}, [])
	useEffect(() => {
		let newMaxPage = Math.ceil(Number(numberOfUsers) / Number(pagination))
		setMaxPage(newMaxPage)
		if (page > newMaxPage) {
			setPage(1)
		}
	}, [numberOfUsers])
	useEffect(() => {
		if (token) {
			fetchData()
			fetchNumberOfEntries()
		}
	}, [token])
	useEffect(() => {
		if (token) {
			fetchData()
			fetchNumberOfEntries()
		}
	}, [page])

	useEffect(() => {
		if (formReset && token) {
			fetchData()
			fetchNumberOfEntries()
		}
	}, [formReset])

	const choosePage = (p: Number) => {
		setPage(p)
		window.scroll(0, 0)
	}

	const submitSearch = () => {
		if (search === '') {
			setPage(1)
		}
		fetchData().then(() => {
			return fetchNumberOfEntries()
		})
	}

	const resetForm = () => {
		setSearch('')
		setPlatform('')
		setStartDate('')
		setEndDate('')
		isFormReset(true)
	}

	return (
		<Container fluid>
			<h2 className="pageTitle">{userType === 'princess' ? 'Princess Signups': 'CashPot Signups'}</h2>
			<Search
				className={'princess-track'}
				setSearch={setSearch}
				search={search}
				submitSearch={submitSearch}
				resetForm={resetForm}
				exportData={exportData}
				hasPlatform={true}
				platform={platform}
				setPlatform={setPlatform}
				hasDate={true}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				exportable={exportable}
				dateRangePlaceholder={'Select signup date range'}
				searchPlaceholder={'Search userId, first name, last name, token..'}
			/>

			<SignupsTable info={info} loading={loading}/>
			<Paginator pagination={pagination} numberOfUsers={numberOfUsers} maxPage={maxPage} page={page}
					   choosePage={choosePage}/>


		</Container>
	)
}

export default Signups
