import {Dispatch} from "react";
import axios from "axios";
import { getToken } from '../components/utils/authStorage'

export const fetchDataAsync = async (url: string, callback: Dispatch<any>) => {
    let data = []
    try {
        const response = await axios.get(url)
        data = response.data
    } catch (e) {
        console.log(e)
    }
    callback(data)
}
export const deleteAsync = async (url: string, callback: any) => {
    try {
        const token = getToken()
        await axios({
            url: url,
            method: 'delete',
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        })
    } catch (e) {
        alert(e.message)
    }
    callback()
}

export const downloadExportedFile = async (res: Response) => {
	let fileName = 'export.csv'
	const headers = res.headers
	const disposition = headers.get('content-disposition') ? headers.get('content-disposition') : ''
	if (res.headers && disposition) {
		let splitHeader = disposition.split('filename=')
		if (splitHeader.length > 0) {
			fileName = splitHeader[1]
		}
	}
	return res.blob().then(function (myBlob: any) {
		const url = URL.createObjectURL(myBlob)
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', fileName)
		link.click()
	})
}