import jwt_decode from 'jwt-decode'


export const storeAuth = (token: string) => {
	localStorage.setItem('ACCESS_TOKEN', token)
	localStorage.setItem('USER_DATA', JSON.stringify(jwt_decode(token)))
}

export const removeAuth = () => {
	localStorage.removeItem('ACCESS_TOKEN')
	localStorage.removeItem('USER_DATA')
}

export const getToken = () => {
	return localStorage.getItem('ACCESS_TOKEN')
}

export const getUserData = (key: string | null = null) => {
	const data: any = localStorage.getItem('USER_DATA')
	if (data) {
		const userData = JSON.parse(data)
		if (key) {
			return userData[key]
		}
		return userData
	}
	return null
}