import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import 'react-datetime/css/react-datetime.css';
import {RaceSchema} from "./RaceSchemas";
import axios from "axios";
import RaceSchemaForm from "./RaceSchemaForm";
import {fetchDataAsync} from "../../helpers/requests";
import { getToken } from '../utils/authStorage'


const EditRaceSchema = (props: any) => {
    const {match: {params: {id = ''} = {}} = {}} = props
    const initialState: RaceSchema = {
        name: '',
        RaceMultiplierSchemas: []
    }
    const postData = async (values: RaceSchema) => {
        try {
            const token = getToken()
            await axios({
                url: `${process.env.REACT_APP_API_URL}/race-schemas/${id}`,
                method: 'put',
                data: values,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            props.history.push("/race-schemas")
        } catch (e) {
            alert(e.message)
        }
    }
    const [raceSchema, setRaceSchema] = useState<RaceSchema>(initialState);
    useEffect(() => {
        fetchDataAsync(`${process.env.REACT_APP_API_URL}/race-schemas/${id}`, setRaceSchema)
    }, []);
    return (
        <Container fluid>
            <h2 className="pageTitle">Edit Race Schema #{id}</h2>
            {
                raceSchema.ID &&
                <RaceSchemaForm initialState={raceSchema} postData={postData}/>
            }
        </Container>
    )
}

export default EditRaceSchema;