import React from "react";
import {Container} from "reactstrap";
import moment from 'moment';
import {LPNotifications} from "./lpusers";

export const Modal = (props: any) => {

    return (
        <Container fluid>
            <div style={{
                position: 'fixed',
                top: 0,
                overflow: 'hidden',
                left: 0,
                maxHeight: props.modalOpen && props.user ? '100vh' : 0,
                height: '100%',
                width: '100vw',
                display: 'flex',
                transition: '0.2s ease max-height',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background: 'rgb(0,0,0,0.2)',
                    width: '100%',
                    height: '100%'
                }}
                     onClick={() => {
                         props.setModalOpen(false)
                         props.setUser(undefined)
                     }}/>
                <div style={{
                    width: '50%',
                    background: '#fff',
                    zIndex: 10,
                    alignSelf: 'center',
                    borderRadius: 10,
                    display: 'flex',
                    maxHeight: '60%',
                    overflow: 'scroll',
                    flexDirection: 'column',
                    padding: 20
                }}>
                    <div style={{alignSelf: 'flex-end', cursor: 'pointer'}} onClick={() => {
                        props.setModalOpen(false)
                        props.setUser(undefined)
                    }}><img src={require('../../assets/x.svg')} alt={'close'}/></div>
                    <div style={{marginTop: 20, display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            textAlign: 'left',
                            borderTop: "1px solid #d3d3d3",
                            borderBottom: "1px solid #d3d3d3",
                            fontWeight: 'bold'
                        }}>
                            <div style={{
                                flex: 1, width: '25%', marginRight: 5,
                                padding: '2px 5px'
                            }}>Type
                            </div>
                            <div style={{
                                flex: 1, width: '25%', marginRight: 5,
                                padding: '2px 5px'
                            }}>Request
                            </div>
                            <div style={{
                                flex: 1, width: '25%', marginRight: 5,
                                padding: '2px 5px'
                            }}>Response
                            </div>
                            <div style={{
                                flex: 1, width: '25%', marginRight: 5,
                                padding: '2px 5px'
                            }}>Notified
                            </div>
                        </div>
                        {props.user && props.user.notifications && props.user?.notifications.map((notif: LPNotifications, i: number) => {
                            return (<div style={{
                                display: 'flex',
                                flex: 1,
                                textAlign: 'left',
                                wordBreak: 'break-all',
                                background: i % 2 === 0 ? '#eee' : '#fff',
                                borderBottom: "1px solid #d3d3d3",
                                fontSize: '14px'
                            }}>
                                <div style={{
                                    flex: 1,
                                    width: '25%',
                                    marginRight: 5,
                                    padding: '2px 5px'
                                }}>{notif.type}</div>
                                <div style={{
                                    flex: 1,
                                    width: '25%',
                                    marginRight: 5,
                                    padding: '2px 5px'
                                }}>{notif.request}</div>
                                <div style={{
                                    flex: 1,
                                    width: '25%',
                                    marginRight: 5,
                                    padding: '2px 5px'
                                }}>{notif.response}</div>
                                <div style={{
                                    flex: 1,
                                    width: '25%',
                                    marginRight: 5,
                                    padding: '2px 5px'
                                }}>{moment(notif.notifiedAt).format('DD/MM/YYYY HH:mm:ss').includes('01/01/0001') ? '-' : moment(notif.notifiedAt).format('DD/MM/YYYY HH:mm:ss')}</div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </Container>
    )
}

