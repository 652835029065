import React from "react";
import {Route, RouteComponentProps, Redirect} from "react-router-dom";
import { getToken } from '../utils/authStorage'

interface Props {
    Component: React.FC<RouteComponentProps>;
    path: string;
    exact?: boolean;
}

const PrivateRoute = ({Component, path, exact = false}: Props) => {
    const isAuthenticated = !!getToken()
    const message = 'Please log in to view this page'

    return (
        <Route
            exact={exact}
            path={path}
            render={(props: RouteComponentProps) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                message,
                                requestedPath: path
                            }
                        }}
                    />
                )
            }
        />
    )
}

export default PrivateRoute;
