import React from 'react'
// @ts-ignore
import RangePicker from 'react-range-picker'
import moment from 'moment'

const DateRangeSelector = (props: any) => {
	const placeholder = ({startDate, endDate}: { startDate?: Date, endDate?: Date }) => {
		if (!startDate || props.startDate === '') {
			return (
				<div className="placeholderWrap">
					<div className="placeholder">{props.dateRangePlaceholder}</div>
				</div>
			)
		}
		let text: string = ''
		if (startDate) {
			text = `${moment(startDate).format('YYYY-MM-DD')}`
		}
		if (endDate) {
			text = `${text} - ${moment(endDate).format('YYYY-MM-DD')}`
		}
		return (
			<div className="placeholderWrap">
				<div className="placeholder">{text}</div>
			</div>
		)
	}
	return (
		<RangePicker
			className={'date-range-selector'}
			placeholder={placeholder}
			closeOnSelect={true}
			// placeholderText={'Select Date Range'}
			// dateFormat={'dd-mm-yyyy'}
			defaultValue={{
				startDate: props.startDate !== '' ? new Date(props.startDate) : undefined,
				endDate: props.endDate !== '' ? new Date(props.endDate) : (new Date())
			}}
			onDateSelected={(from: Date, to: Date) => {
				let fromDate: string = ''
				let toDate: string = ''
				if (from) {
					fromDate = moment(from).format('YYYY-MM-DD')
				}
				if (to) {
					toDate = moment(to).format('YYYY-MM-DD')
				}
				props.setStartDate(fromDate)
				props.setEndDate(toDate)
			}}
			footer={() => {
				return <div></div>
			}}
		/>
	)
}

export default DateRangeSelector