import React from "react";
import {Container} from "reactstrap";
import 'react-datetime/css/react-datetime.css';
import {RaceSchema} from "./RaceSchemas";
import axios from "axios";
import RaceSchemaForm from "./RaceSchemaForm";
import { getToken } from '../utils/authStorage'

const initialState: RaceSchema = {
    name: '',
    RaceMultiplierSchemas: []
}


const CreateRaceSchema = (props: any) => {
    const postData = async (values: RaceSchema) => {
        try {
            const token = getToken()
            await axios({
                url: `${process.env.REACT_APP_API_URL}/race-schemas/`,
                method: 'post',
                data: values,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            props.history.push("/race-schemas")
        } catch (e) {
            alert(e.message)
        }
    }
    return (
        <Container fluid>
            <h2 className="pageTitle">New Race Schema</h2>
            <RaceSchemaForm initialState={initialState} postData={postData}/>
        </Container>
    )
}

export default CreateRaceSchema;