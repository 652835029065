import React, {useEffect, useState} from "react";
import {Button, Container, Table} from "reactstrap";
import {deleteAsync, fetchDataAsync} from "../../helpers/requests";
import moment, {Moment} from "moment";
import Swal from 'sweetalert2'

export interface Race {
    ID?: number,
    name: string,
    startDate: Moment,
    endDate: Moment,
    displayRows: number,
    reward: number,
    bonus: boolean,
    optIn: boolean,
    multiplier: boolean,
    race_schema_id?: number
}

const Races = (props: any) => {
    const [races, setRaces] = useState<Race[]>([]);

    useEffect(() => {
        fetchDataAsync(`${process.env.REACT_APP_API_URL}/races/`, setRaces)
    }, []);

    return (
        <Container fluid>
            <h2 className="pageTitle">Races</h2>
            <Button
                type={'button'}
                color="primary"
                onClick={() => props.history.push("/createMonthlyRace")}>
                New Race
            </Button>
            <Table striped size="sm">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Bonus</th>
                    <th>Opt In</th>
                    <th>Display Rows</th>
                    <th>Reward</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    races.map((race, index) => (
                        <tr key={index}>
                            <td>{race.ID}</td>
                            <td>{race.name}</td>
                            <td>{moment(race.startDate).format('DD-MM-YYYY HH:mm')}</td>
                            <td>{moment(race.endDate).format('DD-MM-YYYY HH:mm')}</td>
                            <td>{race.bonus ? 'Yes' : 'No'}</td>
                            <td>{race.optIn ? 'Yes' : 'No'}</td>
                            <td>{race.displayRows}</td>
                            <td>{race.reward}</td>
                            <td>
                                <Button
                                    type={'button'}
                                    color="danger"
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Are you sure?',
                                            text: 'You will not be able to recover this race!',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Yes, delete it!',
                                            cancelButtonText: 'No, keep it'
                                        }).then((result) => {
                                            if (result.value) {
                                                deleteAsync(`${process.env.REACT_APP_API_URL}/races/${race.ID}`, () => window.location.reload())
                                            }
                                        })
                                    }}>
                                    Delete
                                </Button>
                                <Button
                                    type={'button'}
                                    color="primary"
                                    onClick={() => {
                                        props.history.push(`/races/${race.ID}`)
                                    }}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </Container>
    )
}

export default Races;