import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import {Button, Form, FormGroup, FormText, Input, Label} from "reactstrap";
import 'react-datetime/css/react-datetime.css';
import {RaceMultiplierSchema, RaceSchema} from "./RaceSchemas";
import moment from "moment";

const validateRace = ({name, RaceMultiplierSchemas}: RaceSchema) => {
    let errors: any = {}
    if (!name) {
        errors.name = 'Name is required'
    } else if (name.length > 50) {
        errors.name = 'Name must be at most 50 characters long'
    }
    RaceMultiplierSchemas.forEach((multiplier, index) => {
        if (multiplier.minimum_wager === undefined) {
            errors[`minimum_wager[${index}]`] = 'Minimum Wager is required'
        }
        if (multiplier.day_count === undefined) {
            errors[`day_count[${index}]`] = 'Multiplier is required'
        }
        if (multiplier.multiplier === undefined) {
            errors[`multiplier[${index}]`] = 'Multiplier is required'
        }
    })
    return errors
}


const RaceSchemaForm = (props: any) => {
    const [values, setValues]: [RaceSchema, Dispatch<SetStateAction<RaceSchema>>] = useState(props.initialState);
    const [errors, setErrors]: [any, Dispatch<SetStateAction<any>>] = useState({});
    const addNewMultiplier = () => {
        setValues({
            ...values,
            RaceMultiplierSchemas: [
                ...values.RaceMultiplierSchemas,
                {
                    minimum_wager: 0,
                    day_count: 0,
                    multiplier: 0
                }
            ]
        })
    }
    const removeMultiplier = (index: number) => {
        if (values.RaceMultiplierSchemas[index].ID) {
            values.RaceMultiplierSchemas[index].DeletedAt = moment().format()
        } else {
            values.RaceMultiplierSchemas.splice(index, 1)
        }
        setValues({
            ...values,
            RaceMultiplierSchemas: [
                ...values.RaceMultiplierSchemas
            ]
        })
    }

    const InputError = ({name}: any) => {
        if (errors[name]) {
            return <FormText color={'danger'}>{errors[name]}</FormText>
        }
        return null
    }
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        const errors = validateRace(values)
        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            await props.postData(values)
        }
    }
    const FormStyle = {
        margin: 20
    }
    const {name, RaceMultiplierSchemas} = values
    return (
        <Form style={FormStyle} onSubmit={handleSubmit}>
            <FormGroup row>
                <Label for="name">Name</Label>
                <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => setValues({
                        ...values,
                        name: e.currentTarget.value
                    })}
                />
                <InputError name={'name'}/>
            </FormGroup>
            <FormGroup>
                <Label for="name">Multipliers</Label>
            </FormGroup>
            {
                RaceMultiplierSchemas.map((multiplier: RaceMultiplierSchema, index) => !multiplier.DeletedAt && (
                    <FormGroup row>
                        <FormGroup>
                            <Label for={`minimum_wager[${index}]`}>Minimum Wager</Label>
                            <Input
                                type="number"
                                min={0}
                                name={`minimum_wager[${index}]`}
                                id={`minimum_wager[${index}]`}
                                placeholder="Minimum Wager"
                                value={multiplier.minimum_wager}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    RaceMultiplierSchemas[index].minimum_wager = parseInt(e.currentTarget.value)
                                    setValues({
                                        ...values
                                    })
                                }}
                            />
                            <InputError name={`minimum_wager[${index}]`}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for={`day_count[${index}]`}>Day Count</Label>
                            <Input
                                type="number"
                                min={0}
                                name={`day_count[${index}]`}
                                id={`day_count[${index}]`}
                                placeholder="Day Count"
                                value={multiplier.day_count}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    RaceMultiplierSchemas[index].day_count = parseInt(e.currentTarget.value)
                                    setValues({
                                        ...values
                                    })
                                }}
                            />
                            <InputError name={`day_count[${index}]`}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for={`multiplier[${index}]`}>Multiplier</Label>
                            <Input
                                type="number"
                                min={0}
                                name={`multiplier[${index}]`}
                                id={`multiplier[${index}]`}
                                placeholder="Multiplier"
                                value={multiplier.multiplier}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    RaceMultiplierSchemas[index].multiplier = parseInt(e.currentTarget.value)
                                    setValues({
                                        ...values
                                    })
                                }}
                            />
                            <InputError name={`multiplier[${index}]`}/>
                        </FormGroup>
                        <Button style={{alignSelf: 'center'}} color="danger" type={'button'}
                                onClick={() => removeMultiplier(index)}>Remove</Button>
                    </FormGroup>
                ))
            }
            <Button color="primary" type={'button'} onClick={addNewMultiplier}>New multiplier</Button>
            <br/>
            <Button color="primary" type={'submit'}>Save</Button>
        </Form>
    )
}

export default RaceSchemaForm;