import React from 'react'
import { Button, Input } from 'reactstrap'
// @ts-ignore
import DateRangeSelector from './dateRangeSelector'

export const Search = (props: any) => {
	return (
		<div className={`search-container ${props.className ? props.className : ''}`}>
			<div className="search-wrapper">
				<input
					className="search-input"
					value={props.search}
					placeholder={props.searchPlaceholder ? props.searchPlaceholder : 'Search..'}
					onChange={(e) => {
						props.setSearch(e.target.value)
					}}
					// onChange={(e) => {
					//     props.setSearch(e.target.value, () => {
					//         if (e.target.value === '' && props.search !== '') {
					//             props.submitSearch()
					//         }
					//     })
					// }}
					// onKeyPress={(e) => {
					//     if (e.key === 'Enter') {
					//         props.submitSearch()
					//     }
					// }}
				/>
				{props.hasAdvertiser && (
					<Input style={{width:"8vw"}} type="select" onChange={(e) => {
						props.setAdvertiser(e.target.value)
					}}
						   value={props.advertiser}
						   className="search-dropdown"
					>
						<option value="princess">Princess</option>
						<option value="playgg">PlayGG</option>
					</Input>
				)}
				{props.hasAffiliate && (
					<Input style={{width:"10vw"}} type="select" onChange={(e) => {
						props.setAffiliate(e.target.value)
					}}
						   value={props.affiliateId}
						   className="search-dropdown"
					>
						<option value="">All Affiliates</option>
						<option value="google">Google</option>
						<option value="facebook">Facebook</option>
						<option value="yahoo">Yahoo</option>
					</Input>
				)}
				{props.hasPlatform && (
					<Input type="select" onChange={(e) => {
						props.setPlatform(e.target.value)
					}}
						   value={props.platform}
						   className="search-dropdown"
					>
						<option value="">All Platforms</option>
						<option value="focuus">Focuus</option>
						<option value="princess">Ninja</option>
					</Input>
				)}
				{props.hasDate && (
					<DateRangeSelector
						startDate={props.startDate}
						endDate={props.endDate}
						setStartDate={props.setStartDate}
						setEndDate={props.setEndDate}
						dateRangePlaceholder={props.dateRangePlaceholder}
					/>
				)}
				{(props.hasDate || props.hasAffiliate) && (
					<>
						<Button
							color={'primary'}
							onClick={(e) => {
								props.submitSearch()
							}}>
							Filter
						</Button>
						{(props.affiliateId || props.startDate || props.endDate || props.search) &&
                        <Button
                            color={'secondary'}
                            onClick={(e) => {
								props.resetForm()
							}}>
                            Reset
                        </Button>}
						{(props.exportable) &&
						<Button
						    right
						    color={'success'}
						    onClick={(e) => {
								props.exportData()
							}}>
						    Export
						</Button>}
					</>
				)}
			</div>
		</div>
	)
}


function debounce<Params extends any[]>(
	func: (...args: Params) => any,
	timeout: number,
): (...args: Params) => void {
	let timer: NodeJS.Timeout
	return (...args: Params) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			func(...args)
		}, timeout)
	}
}
