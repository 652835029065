import React from "react";
import {Switch, Route} from "react-router-dom";
import CreateRace from "../Races/CreateRace";
import Dashboard from "../dashboard/dashboard";
import {Button, Container} from "reactstrap";
import Races from "../Races/Races";
import Signups from "../Signups/signups";
import Sidebar from "../utils/sidebar";
import Deposits from "../Deposits/deposits";
import RaceSchemas from "../RaceSchemas/RaceSchemas";
import CreateRaceSchema from "../RaceSchemas/CreateRaceSchema";
import EditRace from "../Races/EditRace";
import EditRaceSchema from "../RaceSchemas/EditRaceSchema";
import LPUsers from "../marketing/lpusers";
import SignupConversion from "../SignupConversions/signupConversion";
import DepositConversion from "../DepositConversion/depositConversion";
import NinjaDeposits from "../NinjaDeposit/ninjaDeposit";
import NinjaSignups from "../NinjaSignup/ninjaSignup";
import { getUserData, getToken, removeAuth } from '../utils/authStorage'

const AppRoutes = (props: any) => {

    function logoutFunction() {
        removeAuth()
        props.history.push('/login')
    }
    const userType = getUserData('userType')

    return (
        <Container fluid className="d-flex flex-row p-0">
            <Sidebar userType={userType}/>
            <Button onClick={logoutFunction} className="logout-btn" color="primary">Logout</Button>
            <Container fluid className="main">
                <Switch>
                    <Route path="/signups" component={Signups}/>
                    <Route path="/deposits" component={Deposits}/>
                    <Route path="/lpusers" component={LPUsers}/>
                    <Route path="/races/:id" component={EditRace}/>
                    <Route path="/races" component={Races}/>
                    <Route path="/createMonthlyRace" component={CreateRace}/>
                    <Route path="/race-schemas/:id" component={EditRaceSchema}/>
                    <Route path="/race-schemas" component={RaceSchemas}/>
                    <Route path="/createRaceSchema" component={CreateRaceSchema}/>
                    <Route path="/signup-conversion" component={SignupConversion}/>
                    <Route path="/deposit-conversion" component={DepositConversion}/>
                    <Route path="/ninja-deposit" component={NinjaDeposits}/>
                    <Route path="/ninja-signup" component={NinjaSignups}/>
                    <Route path="/" component={Dashboard}/>
                </Switch>
            </Container>

        </Container>
    )
};

export default AppRoutes;
