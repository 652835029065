import React from 'react'

export interface LPPage {
    name: number,
    active: boolean
}

export interface RecordLPPage{
	[P: string]: LPPage
}

export const Paginator = (props: any) => {
	let {page: currentPage, maxPage: pageCount} = props
	if (currentPage < 1) {
		currentPage = 1
	}
	if (pageCount < 1) {
		pageCount = 1
	}
	// overwrite pages to avoid duplicates
	const pageStore: RecordLPPage = {}
	// add first, last and current page
	pageStore[1] = {name: 1, active: currentPage === 1}
	pageStore[pageCount] = {name: pageCount, active: currentPage === pageCount}
	pageStore[currentPage] = {name: currentPage, active: true}

	// add prev page and if < 1 then add another next page if possible
	if (currentPage - 1 >= 1) {
		pageStore[currentPage - 1] = {name: currentPage - 1, active: false}
	} else if (currentPage + 2 <= pageCount) {
		pageStore[currentPage + 2] = {name: currentPage + 2, active: false}
	}

	// add next page and if > pageCount add another prev page if possible
	if (currentPage + 1 <= pageCount) {
		pageStore[currentPage + 1] = {name: currentPage + 1, active: false}
	} else if (currentPage - 2 >= 1) {
		pageStore[currentPage - 2] = {name: currentPage - 2, active: false}
	}

	// sort values so that pages can appear in order
	const pages = Object.values(pageStore)
		.sort((pageA: LPPage, pageB: LPPage) => Number(pageA.name) - Number(pageB.name))

    return (

        <div
            style={{
                display: "flex",
                width: ' 100%',
                justifyContent: 'flex-end',
                alignSelf: 'flex-end',
                overflow: 'hidden',
                marginBottom: 10
            }}>
            {
                pages && pages.map((p: LPPage) => {
                    return (<div onClick={() => props.choosePage(p.name)} style={{
                        background: !p.active ? '#eeeeee' : '#4A4063',
                        display: 'flex',
                        padding: 10,
                        userSelect: 'none',
                        marginRight: 5,
                        transition: '0.5s ease',
                        borderRadius: '50%',
                        width: 50,
                        height: 50,
                        alignItems: 'center',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        color: !p.active ? '#000' : '#fff'
                    }}>{Number(p.name)}</div>)
                })
            }
        </div>
    )
}
