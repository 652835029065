import React from "react";
import {Container} from "reactstrap";

const Dashboard = () => {


    return(
        <Container>
        </Container>

    )
}

export default Dashboard;