import React from "react";
import {Table} from "reactstrap";
import {SignupConversion} from "./signupConversion";
import moment from "moment";

export const SignupConversionTable = (props: any) => {
    return (
        <Table striped size="sm">
            {props.loading && <div style={{
                position: 'absolute',
                width: 'calc(100vw - 15%)',
                height: '100vh',
                top: 0,
                left: '15%',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <img style={{alignSelf: 'center'}} src={require('../../assets/loader.svg')} alt={'loader'}/>
            </div>}
            <thead>
            <tr>
                <th>User ID</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Affiliate Id</th>
                <th>Affiliate Token</th>
                <th>Response Status</th>
                <th>Error Type</th>
                <th>Registration Time</th>
                <th>Conversion sent at</th>
            </tr>
            </thead>
            <tbody>
            {props.deposits && props.deposits.map((signup: SignupConversion) => (
                <tr>
                    <td>{signup.userId}</td>
                    <td>{signup.firstName}</td>
                    <td>{signup.lastName}</td>
                    <td>{signup.affiliateId}</td>
                    <td>{signup.myAffiliateToken}</td>
                    <td>{signup.responseStatus}</td>
                    <td>{signup.errorType}</td>
                    <td>{moment(signup.registrationTime).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{moment(signup.conversionSentAt).format('DD/MM/YYYY HH:mm:ss')}</td>

                </tr>
            ))}
            </tbody>
        </Table>
    )
}
