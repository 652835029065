import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import { DepositConversionTable } from './depositConversionTable'
import { Paginator } from '../marketing/paginator'
import { Search } from '../utils/search'
import { downloadExportedFile } from '../../helpers/requests'
import { getToken } from '../utils/authStorage'

export interface DepositConversion {
	affiliateId: string
	amount: number
	conversionName: string
	conversionSentAt: Date
	firstName: string
	lastName: string
	myAffiliateToken: string
	paymentId: number
	paymentTime: Date
	responseStatus: string
	errorType: string
	userId: number
}


const DepositConversions = (props: any) => {
	const [deposits, setDeposits] = useState<DepositConversion[]>([])
	const [page, setPage] = useState<Number>(1)
	const [numberOfUsers, setNumberOfUsers] = useState<Number>(0)
	const [pagination, setPagination] = useState<Number>(100)
	const [search, setSearch] = useState<string>('')
	const [advertiser, setAdvertiser] = useState<string>('princess')
	const [affiliateId, setAffiliateId] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [maxPage, setMaxPage] = useState<Number>(0)
	const [token, setToken] = useState('')

	const [endDate, setEndDate] = useState<string>('')
	const [startDate, setStartDate] = useState<string>('')
	const [formReset, isFormReset] = useState<boolean>(false)
	const [exportable, setExportable] = useState<boolean>(false)

	const fetchData = () => {
		setLoading(true)
		isFormReset(false)
		let canExport = false
		let url = `${process.env.REACT_APP_CONVERSION_API}/deposits?page=${page}`
		if (search !== '') {
			url = `${url}&search=${search}`
			canExport = true
		}
		if (advertiser !== '') {
			url = `${url}&advertiser=${advertiser}`
		}
		if (affiliateId !== '') {
			url = `${url}&platform=${affiliateId}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
			canExport = true
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
			canExport = true
		}
		setExportable(canExport)

		return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
			.then(response => response.json())
			.then(response => {
				setDeposits(response.results)
				setNumberOfUsers(response.pagination.total)
				setLoading(false)
			})
			.catch(error => {
				setDeposits([])
				setLoading(false)
			})
	}

	const exportData = () => {
		setLoading(true)
		let url = `${process.env.REACT_APP_CONVERSION_API}/deposits/export?1=1`
		if (search !== '') {
			url = `${url}&search=${search}`
		}
		if (advertiser !== '') {
			url = `${url}&advertiser=${advertiser}`
		}
		if (affiliateId !== '') {
			url = `${url}&platform=${affiliateId}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
		}
		return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
			.then(downloadExportedFile)
			.catch(error => {
				console.log(error)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		const tokenFetch = async () => {
			const tok = getToken()
			if (tok) {
				setToken(tok)
			}
		}
		tokenFetch()
	}, [])
	useEffect(() => {
		let newMaxPage = Math.ceil(Number(numberOfUsers) / Number(pagination))
		setMaxPage(newMaxPage)
		if (page > newMaxPage) {
			setPage(1)
		}
	}, [numberOfUsers])
	useEffect(() => {
		if (token) {
			fetchData()
		}
	}, [token])
	useEffect(() => {
		if (token) {
			fetchData()
		}
	}, [page])

	useEffect(() => {
		if (formReset && token) {
			fetchData()
		}
	}, [formReset])

	const choosePage = (p: Number) => {
		setPage(p)
		window.scroll(0, 0)
	}

	const submitSearch = () => {
		fetchData()
		if (search === '') {
			setPage(1)
		}
	}
	const resetForm = () => {
		setSearch('')
		setAffiliateId('')
		setStartDate('')
		setEndDate('')
		isFormReset(true)
	}

	return (
		<Container fluid>
			<h2 className="pageTitle">Deposit Conversion</h2>
			<Search
				className={'conversions'}
				setSearch={setSearch}
				search={search}
				submitSearch={submitSearch}
				resetForm={resetForm}
				exportData={exportData}
				hasAdvertiser={true}
				advertiser={advertiser}
				setAdvertiser={setAdvertiser}
				hasAffiliate={true}
				affiliateId={affiliateId}
				setAffiliate={setAffiliateId}
				hasDate={true}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				exportable={exportable}
				dateRangePlaceholder={'Select deposit date range'}
				searchPlaceholder={'Search userId, email, name, phone number, token..'}
			/>
			<DepositConversionTable deposits={deposits} loading={loading}/>
			<Paginator pagination={pagination} numberOfUsers={numberOfUsers} maxPage={maxPage} page={page}
					   choosePage={choosePage}/>


		</Container>
	)
}

export default DepositConversions
