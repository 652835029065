import React, {useState} from 'react'
import {Container, Row, Col, Form, FormGroup, Label, Input, Button} from 'reactstrap'
import jwt_decode from 'jwt-decode'
import { storeAuth } from '../utils/authStorage'

interface authData {
    email: string
    password: string
}

const Login = (props: any) => {
    const [auth, setAuth] = useState<authData>({email: '', password: ''})
    const [errorMessage, setErrorMessage] = React.useState("");

    const onSubmit = (e: any) => {
        e.preventDefault()
        setErrorMessage("")
        fetch(`${process.env.REACT_APP_AUTH_DOMAIN}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(auth)
        })
            .then(response => response.json())
            .then(data => {
                if (data.code === 200) {
                    storeAuth(data.token)
                    props.history.push('/')
                } else {
                    let message= data.message || "Auth error"
                    setErrorMessage(`${message.slice(0,1).toUpperCase()}${message.slice(1)}`)
                    props.history.push('/login')
                }
            })
            .catch(error => setAuth({email: '', password: ''}))
    }
    return (
        <Container fluid>
            <Row>
                <Col sm="12" md={{size: 6, offset: 3}}>
                    <h2 className="login-title">Ops Login</h2>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md={{size: 6, offset: 3}}>
                    <Form onSubmit={onSubmit} className="login-form">
                        <FormGroup>
                            <Label for="email">Username:</Label>
                            <Input type="text"
                                   name="email"
                                   id="email"
                                   onChange={(e) => setAuth({...auth, email: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password:</Label>
                            <Input type="password" name="password" id="password"
                                   onChange={(e) => setAuth({...auth, password: e.target.value})}
                            />
                        </FormGroup>
                        {errorMessage && <div className="auth-error"> {errorMessage} </div>}
                        <Button color="primary">Login</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default Login
