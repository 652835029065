import React from "react";
import moment from "moment";
import { Table} from "reactstrap";
import {User} from "./signups";

export const SignupsTable = (props: any) => {

    return (
    <Table striped size="sm">
        {props.loading && <div style={{
            position: 'absolute',
            width: 'calc(100vw - 15%)',
            height: '100vh',
            top: 0,
            left: '15%',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }}>
            <img style={{alignSelf: 'center'}} src={require('../../assets/loader.svg')} alt={'loader'}/>
        </div>}
        <thead>
        <tr>
            <th>User ID</th>
            <th>Firstname</th>
            <th>Lastname</th>
            <th>Registered at</th>
            <th>Affiliate ID</th>
            <th>Affiliate Token</th>
            <th>Platform</th>
            <th>Response</th>
            <th>Reported at</th>
        </tr>
        </thead>
        <tbody>
        {props.info && props.info.map((user: User) => (
            <tr>
                <td>{user.userId}</td>
                <td>{user.FirstName}</td>
                <td>{user.LastName}</td>
                <td>{user.RegistrationTime}</td>
                <td>{user.AffiliateId}</td>
                <td>{user.AffiliateToken}</td>
                <td>{user.platform == 'princess' ? 'ninja' : user.platform}</td>
                <td>{user.TrackStatus}</td>
                <td>{moment(user.CreatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
            </tr>
        ))}
        </tbody>
    </Table>
    )
}
