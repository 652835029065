import fetchIntercept from 'fetch-intercept';
import { removeAuth } from '../components/utils/authStorage'

export function register() {
    fetchIntercept.register({
        request: function (url, config) {
            return [url, config];
        },

        requestError: function (error) {
            return Promise.reject(error);
        },

        response: function (response) {
            if (response.status === 403) {
                removeAuth()
                window.location.href = "/login";
            }
            return response;
        },

        responseError: function (error) {
            return Promise.reject(error);
        }
    });
}
