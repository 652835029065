import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/index.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AppRoutes from "./components/Routes/AppRoutes";
import * as serviceWorker from './serviceWorker';
import "bootstrap/dist/css/bootstrap.css";
import Login from "./components/auth/login";
import PrivateRoute from "./components/auth";
import {register} from './helpers/interceptor';

register();

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route exact path="/login" component={Login}/>
                <PrivateRoute path="/" Component={AppRoutes}/>
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
