import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import { DepositsTable } from './depositsTable'
import { Paginator } from '../marketing/paginator'
import { Search } from '../utils/search'
import { downloadExportedFile } from '../../helpers/requests'
import { getToken, getUserData } from '../utils/authStorage'

export interface Deposit {
	UserId: number
	AffiliateId: number
	AffiliateToken: string
	CreatedAt: Date
	FirstName: string
	LastName: string
	Amount: number
	DepositType: string
	TrackStatus: string,
	PaymentId: string,
	PaymentType: string
	platform: string
}


const Deposits = (props: any) => {
	const userType = getUserData('userType')
	let baseUrl = `${process.env.REACT_APP_DEPOSITS_API}`
	if (userType === 'cashpot') {
		baseUrl = `${baseUrl}/cashpot`
	}

	const [deposits, setDeposits] = useState<Deposit[]>([])
	const [page, setPage] = useState<Number>(1)
	const [numberOfUsers, setNumberOfUsers] = useState<Number>(0)
	const [pagination, setPagination] = useState<Number>(100)
	const [search, setSearch] = useState<string>('')
	const [platform, setPlatform] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [maxPage, setMaxPage] = useState<Number>(0)
	const [token, setToken] = useState('')

	const [endDate, setEndDate] = useState<string>('')
	const [startDate, setStartDate] = useState<string>('')
	const [formReset, isFormReset] = useState<boolean>(false)
	const [exportable, setExportable] = useState<boolean>(false)

	const fetchData = () => {
		setLoading(true)
		isFormReset(false)
		let canExport = false
		let url = `${baseUrl}/deposits/list?page=${page}`
		if (search !== '') {
			url = `${url}&search=${search}`
			canExport = true
		}
		if (platform !== '') {
			url = `${url}&platform=${platform}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
			canExport = true
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
			canExport = true
		}
		setExportable(canExport)

		return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
			.then(response => response.json())
			.then(response => {
				setDeposits(response)
			})
			.catch(error => {
				setDeposits([])
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const exportData = () => {
		setLoading(true)
		let url = `${baseUrl}/deposits/export?1=1`
		if (search !== '') {
			url = `${url}&search=${search}`
		}
		if (platform !== '') {
			url = `${url}&platform=${platform}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
		}
		return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
			.then(downloadExportedFile)
			.catch(error => {
				console.log(error)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const fetchNumberOfEntries = () => {
		setLoading(true)
		let url = `${baseUrl}/deposits/countData?1=1`
		if (search !== '') {
			url = `${url}&search=${search}`
		}
		if (platform !== '') {
			url = `${url}&platform=${platform}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
		}
		return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
			.then(response => response.json())
			.then(response => {
				setNumberOfUsers(response)
			})
			.catch(error => {
				setDeposits([])
			})
			.finally(() => {
				setLoading(false)
			})
	}
	useEffect(() => {
		const tokenFetch = async () => {
			const tok = getToken()
			if (tok) {
				setToken(tok)
			}
		}
		tokenFetch()
	}, [])
	useEffect(() => {
		let newMaxPage = Math.ceil(Number(numberOfUsers) / Number(pagination))
		setMaxPage(newMaxPage)
		if (page > newMaxPage) {
			setPage(1)
		}
	}, [numberOfUsers])
	useEffect(() => {
		if (token) {
			fetchData()
			fetchNumberOfEntries()
		}
	}, [token])
	useEffect(() => {
		if (token) {
			fetchData()
			fetchNumberOfEntries()
		}
	}, [page])

	useEffect(() => {
		if (formReset && token) {
			fetchData()
			fetchNumberOfEntries()
		}
	}, [formReset])

	const choosePage = (p: Number) => {
		setPage(p)
		window.scroll(0, 0)
	}

	const submitSearch = () => {
		if (search === '') {
			setPage(1)
		}
		fetchData().then(() => {
			return fetchNumberOfEntries()
		})
	}

	const resetForm = () => {
		setSearch('')
		setPlatform('')
		setStartDate('')
		setEndDate('')
		isFormReset(true)
	}
	return (
		<Container fluid>
			<h2 className="pageTitle">{userType === 'princess' ? 'Princess Deposits' : 'CashPot Deposits'}</h2>
			<Search
				className={'princess-track'}
				setSearch={setSearch}
				search={search}
				submitSearch={submitSearch}
				resetForm={resetForm}
				exportData={exportData}
				hasPlatform={true}
				platform={platform}
				setPlatform={setPlatform}
				hasDate={true}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				exportable={exportable}
				dateRangePlaceholder={'Select deposit date range'}
				searchPlaceholder={'Search userId, first name, last name, token..'}
			/>

			<DepositsTable deposits={deposits} loading={loading}/>
			<Paginator pagination={pagination} numberOfUsers={numberOfUsers} maxPage={maxPage} page={page}
					   choosePage={choosePage}/>

		</Container>
	)
}

export default Deposits
