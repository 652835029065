import React, {useEffect, useState} from "react";
import {Button, Container, Table} from "reactstrap";
import {deleteAsync, fetchDataAsync} from "../../helpers/requests";
import Swal from "sweetalert2";


export interface RaceSchema {
    ID?: number,
    name: string,
    RaceMultiplierSchemas: RaceMultiplierSchema[]
}

export interface RaceMultiplierSchema {
    ID?: number,
    minimum_wager: number,
    day_count: number,
    multiplier: number,
    DeletedAt?: string
}


const RaceSchemas = (props: any) => {
    const [raceSchemas, setRaceSchemas] = useState<RaceSchema[]>([]);

    useEffect(() => {
        fetchDataAsync(`${process.env.REACT_APP_API_URL}/race-schemas/`, setRaceSchemas)
    }, []);

    return (
        <Container fluid>
            <h2 className="pageTitle">Race schemas</h2>
            <Button
                type={'button'}
                color="primary"
                onClick={() => props.history.push("/createRaceSchema")}>
                New Race Schema
            </Button>
            <Table striped size="sm">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    raceSchemas.map((raceSchema, index) => (
                        <tr key={index}>
                            <td>{raceSchema.ID}</td>
                            <td>{raceSchema.name}</td>
                            <td>
                                <Button
                                    type={'button'}
                                    color="danger"
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'Are you sure?',
                                            text: 'You will not be able to recover this race schema!',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Yes, delete it!',
                                            cancelButtonText: 'No, keep it'
                                        }).then((result) => {
                                            if (result.value) {
                                                deleteAsync(`${process.env.REACT_APP_API_URL}/race-schemas/${raceSchema.ID}`, () => window.location.reload())
                                            }
                                        })
                                    }}>
                                    Delete
                                </Button>
                                <Button
                                    type={'button'}
                                    color="primary"
                                    onClick={() => {
                                        props.history.push(`/race-schemas/${raceSchema.ID}`)
                                    }}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </Container>
    )
}

export default RaceSchemas;
