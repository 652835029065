import React from "react";
import {Table} from "reactstrap";
import {NinjaDeposit} from "./ninjaDeposit";
import moment from "moment";

export const NinjaDepositTable = (props: any) => {
    return (
        <Table striped size="sm">
            {props.loading && <div style={{
                position: 'absolute',
                width: 'calc(100vw - 15%)',
                height: '100vh',
                top: 0,
                left: '15%',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <img style={{alignSelf: 'center'}} src={require('../../assets/loader.svg')} alt={'loader'}/>
            </div>}
            <thead>
            <tr>
                <th>Username</th>
                <th>Payment Id</th>
                <th>Payment Time</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Payment Type</th>
                <th>Affiliate Token</th>
                <th>Response Status</th>
                <th>Response Payload</th>
                <th>Tracked At</th>
            </tr>
            </thead>
            <tbody>
            {props.deposits && props.deposits.map((deposit: NinjaDeposit) => (
                <tr>
                    <td>{deposit.username}</td>
                    <td>{deposit.paymentId}</td>
                    <td>{moment(deposit.paymentTime).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{deposit.amount}</td>
                    <td>{deposit.currency}</td>
                    <td>{deposit.paymentType}</td>
                    <td>{deposit.affiliateToken}</td>
                    <td>{deposit.responseStatus}</td>
                    <td>{deposit.responsePayload}</td>
                    <td>{moment(deposit.trackedAt).format('DD/MM/YYYY HH:mm:ss')}</td>

                </tr>
            ))}
            </tbody>
        </Table>
    )
}
