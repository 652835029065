import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import {Modal} from "./modal";
import {UserTable} from './userTable'
import {Paginator} from "./paginator";
import {Search} from "../utils/search";
import { downloadExportedFile } from '../../helpers/requests'
import { getToken } from '../utils/authStorage'


export interface LPNotifications {
    type: string,
    request: string,
    response: string,
    notifiedAt: string
}


export interface LPUser {
    landingPage: string
    email: string
    phone: string
    createdAt: Date
    status: string
    sms: string
    sopranoResponse: string
    emailTemplate: string
    sendgridResponse: string
    notifiedAt: Date
    tid: string,
    notifications: Array<LPNotifications>
}


const LPUsers = (props: any) => {
    const [data, setData] = useState<LPUser[]>([]);
    const [page, setPage] = useState<Number>(1)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [user, setUser] = useState<LPUser>()
    const [numberOfUsers, setNumberOfUsers] = useState<Number>(0)
    const [pagination, setPagination] = useState<Number>(100)
    const [search, setSearch] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [maxPage, setMaxPage] = useState<Number>(0)
    const [token, setToken] = useState('')

    const [endDate, setEndDate] = useState<string>('')
    const [startDate, setStartDate] = useState<string>('')
    const [formReset, isFormReset] = useState<boolean>(false)
    const [exportable, setExportable] = useState<boolean>(false)

    const fetchData = () => {
        setLoading(true)
        isFormReset(false)
        let canExport = false
        let url = `${process.env.REACT_APP_MARKETING_API}/usersData?page=${page}`

        if (search !== '') {
            url = `${url}&search=${search}`
            canExport = true
        }
        if (startDate !== '') {
            url = `${url}&from=${startDate}`
            canExport = true
        }
        if (endDate !== '') {
            url = `${url}&to=${endDate}`
            canExport = true
        }
        setExportable(canExport)

        return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
            .then(response => response.json())
            .then(response => {
                setData(response)
            })
            .catch(error => setData([]))
            .finally(()=>{
                setLoading(false)
            })
    }

    const exportData = () => {
        setLoading(true)
        let url = `${process.env.REACT_APP_MARKETING_API}/export?1=1`
        if (search !== '') {
            url = `${url}&search=${search}`
        }
        if (startDate !== '') {
            url = `${url}&from=${startDate}`
        }
        if (endDate !== '') {
            url = `${url}&to=${endDate}`
        }

        return fetch(url, {headers: {Authorization: 'Bearer ' + token}})
            .then(downloadExportedFile)
            .catch(error => console.log(error))
            .finally(()=>{
                setLoading(false)
            })
    }

    const fetchNumberOfEntries = () => {
        setLoading(true)
		let url = `${process.env.REACT_APP_MARKETING_API}/countData?1=1`
		if (search !== '') {
			url = `${url}&search=${search}`
		}
		if (startDate !== '') {
			url = `${url}&from=${startDate}`
		}
		if (endDate !== '') {
			url = `${url}&to=${endDate}`
		}
        fetch(url, {headers: {Authorization: 'Bearer ' + token}})
            .then(response => response.json())
            .then(response => {
                setNumberOfUsers(response)
            })
            .catch(error => setData([]))
			.finally(()=>{
				setLoading(false)
			});
    }
    useEffect(() => {
        const tokenFetch = async () => {
            const tok = getToken()
            if (tok) {
                setToken(tok)
            }
        }
        tokenFetch()
    }, [])
    useEffect(() => {
        if (token) {
            fetchData()
            fetchNumberOfEntries()
        }
    }, [token])

    useEffect(() => {
        let newMaxPage = Math.ceil(Number(numberOfUsers) / Number(pagination))
        setMaxPage(newMaxPage)
        if (page > newMaxPage) {
            setPage(1)
        }
    }, [numberOfUsers])

    useEffect(() => {
        if (token) {
            fetchData()
            fetchNumberOfEntries()
        }
    }, [page])

    useEffect(() => {
        if (formReset && token) {
            fetchData()
            fetchNumberOfEntries()
        }
    }, [formReset])

    const choosePage = (p: Number) => {
        setPage(p)
        window.scroll(0, 0)
    }

    const submitSearch = () => {
        if (search === '') {
            setPage(1)
        }
        fetchData().then(()=>{
            return fetchNumberOfEntries()
        })
    }

    const resetForm = () => {
        setSearch('')
        setStartDate('')
        setEndDate('')
        isFormReset(true)
    }

    return (
        <Container fluid>
            <h2 className="pageTitle">LP Data</h2>
            <Search
				setSearch={setSearch}
				search={search}
				submitSearch={submitSearch}
				resetForm={resetForm}
                exportData={exportData}
				hasDate={true}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				exportable={exportable}
				dateRangePlaceholder={'Select lp data range'}
				searchPlaceholder={'Search email, phone..'}
            />
            <UserTable data={data} loading={loading} setModalOpen={setModalOpen} setUser={setUser}/>
            <Paginator pagination={pagination} numberOfUsers={numberOfUsers} maxPage={maxPage} page={page}
                       choosePage={choosePage}/>

            <Modal user={user} setModalOpen={setModalOpen} modalOpen={modalOpen} setUser={setUser}/>
        </Container>
    )
}

export default LPUsers;
